.modal-upload{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color:white;
    z-index: 1000;
   
}

.overlay-style{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    /* background-color:rgba(242, 242, 242, 1); */
    background-color: rgba(0,0,0,0.7);
    ;
    z-index: 1000;

}